import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import ShareSocial from "../components/share"
import {Container} from 'react-bootstrap'
import AdvertContact from "../components/advert"
import SEO from "../components/seo"
import Img from "gatsby-image"
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
  // const imgClassName = `h-100 w-100 serviceImg ${frontmatter.imageclass}`
  return (
    <>
      <Header />
      <SEO title={frontmatter.title} />
      <Container className="mt-2">
        <div className="blog-post-container" style={{display:'flex', justifyContent: 'center'}}>
          <div className="blog-post mt-4" style={{maxWidth: 900}}>
            <h1 style={{textAlign: "center"}}>{frontmatter.title}</h1>
            <h6 className="mt-0 mb-3">{frontmatter.date}</h6>
            <h5 className="mb-4">{frontmatter.blurb}</h5>
            {/* {frontmatter.author &&
              <h6 className="mt-0 mb-3">{frontmatter.author}</h6>
            } */}
            <Img fluid={featuredImgFluid} className="mb-4"/>
            {/* <div className={imgClassName}></div> */}
            <div
              className="blog-post-content mt-4"
              dangerouslySetInnerHTML={{ __html: html }}
              align='justify'
            />
          </div>
        </div>
        <ShareSocial post={markdownRemark}/>
        <AdvertContact />
      </Container>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        blurb
        # author
      }
    }
  }
`
